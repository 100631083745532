export const EN = {
    CHAPTERS: 'Chapters',
    CHAPTERS_LIST: 'Chapters',
    LOADING: 'Loading',
    DASHBOARD: 'Dashboard',
    CATEGORIES: 'Categories',
    TAGS: 'Tags',
    TRENDS: 'Trends',
    BANNERS: 'Banners',
    WIDGETS: 'Widgets',
    PAGES: 'Pages',
    USERS: 'Users',
    DOCUMENTS: 'Documents',
    ARTICLE: 'Article',
    EPISODE: 'Videos',
    GALLERY: 'Gallery',
    INFOGRAPHIC: 'Long read',
    AUTHORS: 'Authors',
    GROUPS: 'Groups',
    PERMISSIONS: 'Permissions',
    PROFILE: 'Profile',
    LOGOUT: 'Logouts',
    NO_EVENTS: 'Empty',
    WELCOME_MESSAGE: 'Welcome to RTCore a.0.0.1',
    DASHBOARD_WELCOME: 'Welcome',
    ARTICLE_LIST: 'Articles',
    EPISODE_LIST: 'Episodes',
    GALLERY_LIST: 'Galleries',
    INFOGRAPHIC_LIST: 'Infographics',
    LOADING_DOCUMENTS: 'Loading',
    EDIT: 'edit',
    VIEW: 'preview',
    DELETE: 'delete',
    CROSSLINKS: 'crosslinks',
    SHOW_ALL_TAGS: 'Show all tags',
    HIDE_ALL_TAGS: 'Hide all tags',
    IMAGE: 'Image',
    COVER: 'Cover',
    VIDEO: 'Video',
    EXTERNALVIDEO: 'External video',
    CODE: 'Code',
    CATEGORY: "Category",
    AUTHOR: 'Author',
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
    PREPUBLISHED: 'pre published',
    CREATE: 'Create',
    TITLE_OR_URL: 'Title or url',
    SELECT_STATUS: 'Select status',
    SELECT_TAGS: 'Select tags',
    SELECT_CATEGORY: 'Select category',
    DATE_FROM: 'Date from',
    DATE_TO: 'Date to',
    ALL: 'all',
    EMPTY_DOCUMENTS: 'Empty',
    CLEAR_FILTER: 'Clear',
    CREATED: 'Create',
    UPDATED: 'Update',
    AT: 'at',
    SURE_DELETE: 'You are sure?',
    CLOSE: 'Close',
    NOT_SET: '[not set]',
    ARTICLE_ADD: 'Create',
    UPLOAD_TEXT: 'Click or drag files',
    COVER_LABEL: 'Cover',
    UPLOAD_NEW: 'New Image',
    UPLOAD_SEL: 'Archive',
    NEWS: 'News',
    ARTICLES: 'Articles',
    TITLE: 'Title',
    UPDATE_DOCUMENT: 'Update',
    DOC_STATUS: 'Status',
    CREATE_BY: 'Created by',
    UPDATED_BY: 'Updated by',
    HISTORY: 'History',
    SUMMARY: 'Summary',
    DELETE_MEDIA: 'Delete',
    TEXT: 'Text',
    SLUG: 'Slug',
    PUBLISHED_AT: 'Published at',
    COORDS: 'Coords',
    COORDS_HELP: 'Select lat/lang',
    HIDE_MAP: 'Hide map',
    SHOW_MAP: 'Show map',
    GENERATE: 'Generate',
    PUBLISH_IN: 'Publish in',
    SUB_CATEGORY: 'Subcategories',
    VIEW_FIELD: 'View',
    SEO_TITLE: 'Seo title',
    SEO_DESCRIPTION: 'Seo description',
    REDIRECT_TO: 'Redirect',
    SEO_AND_VIEW: 'Seo and template',
    published: 'published',
    DOCUMENT_HISTORY_EVENT: 'Document history',
    RECOVER: 'Recover',
    TITLE_ERROR_FILL: 'Fill title',
    SUMMARY_ERROR_FILL: 'Fill summary',
    SLUG_PLACEHOLDER: 'Fill slug',
    SLUG_ERROR: 'Fill slug',
    SELECT_CATEGORIES: 'Select category',
    SELECT_AUTHORS_ERROR: 'Select author',
    AUTHORS_PLACEHOLDER: 'Select authors',
    TITLE_PLACEHOLDER: 'Title',
    SUMMARY_PLACEHOLDER: 'Lead',
    SELECT_SUB_CATEGORIES: 'Subcategory',
    SELECT_TRENDS: 'Trend',
    SELECT_CROSSLINKS: 'Crosslinks',
    NO_HISTORY: 'Empty',
    ARTICLE_EDIT: 'Edit',
    EPISODE_ADD: 'Create',
    EPISODE_EDIT: 'Edit',
    COORDS_MAP_HELP: 'Click to map for add point',
    GALLERY_EDIT: 'Edit',
    CREATE_BTN: 'Create',
    PUBLIC_ID: 'ID',
    CREATED_AT: 'Created',
    UPDATED_AT: 'Updates',
    ACTIONS: 'Actions',
    CREATE_DOCUMENT: 'Create',
    DOCUMENT_UPDATE_ERROR: 'Something went wrong',
    DOCUMENT_UPDATE_SUCCESS: 'Document has been updated',
    DOCUMENT_DELETED: 'Document has been deleted',
    DOCUMENT_DELETE_ERROR: 'Something went wrong',
    DOCUMENTS_LOAD_ERROR: 'Something went wrong',
    REPEAT_AGAIN_LATER: 'Repeat again later',
    DOCUMENT_UPDATED: 'Document has been created',
    LOGIN: 'Login',
    AUTHORIZATION_WAIT: 'Wait please',
    AUTHORIZATION_ERROR: 'Something went wrong',
    CLEAR: 'Clear',
    APPLY: 'Apply',
    SEARCH_MEDIA_PLACEHOLDER: 'Title or copyright',
    REMOVE: 'Delete',
    ADD: 'Add',
    SOURCE: 'Source',

    REMOVE_ALL: 'Delete all',
    SAVE_ALL: 'Save all',
    UPLOAD_FIRST_ROW: 'Click or drag file here',
    UPLOAD_NEXT_ROW: ' ',
    MEDIA: 'Media',
    UPLOAD: 'Upload',
    SEARCH: 'Search',
    MEDIA_LIST: 'Media list',
    WIDGET_ADD_TITLE: 'Create',
    EDIT_WIDGET: 'Edit',
    VIEW_WIDGET: 'View',
    WIDGET_UPDATED: 'Widget has been updates',
    WIDGET_CREATED: 'Widget has been created',
    MAX_SIZE: 'Documents count',
    DOCS_SIZE: 'Documents count',
    DOC_TYPES: 'Documents types',
    ADMIN_TITLE: 'Title (admin)',
    WIDGETS_LIST: 'Widgets list',
    SAVE: 'Save',
    WIDGET_NOT_FOUND: 'Widget not found',
    MAXIMUM_ELEMENT_IN_WIDGET: 'Documents in widget',
    WIDGETS_EDIT: 'Edit',
    TAG_LIST: 'Tags',
    CATEGORY_LIST: 'Categories',
    AUTHORS_LIST: 'Authors',
    PAGES_LIST: 'Pages',
    HREF: 'Link',
    TEXT_PLACEHOLDER: 'Text',
    REDIRECT_TO_ERROR: 'Error in redirect url',
    SLUG_FILL_ERROR: 'Error in slug',
    PAGE_LIST: 'Pages',
    BANNER_LIST: 'Banners',
    TREND_LIST: 'Trends',
    TREND_ADD: 'Create',
    TREND_EDIT: 'Edit',
    BANNER_ADD: 'Create',
    BANNER_EDIT: 'Edit',
    OTHER: 'Other',
    PREVIEW: 'Preview',
    WIDGET_TITLE: 'Title',
    ADMIN_WIDGET_TITLE: 'Admin title',
    AUTHOR_LIST: 'Authors',
    DOCUMENT_CREATE_FINISH: 'Documents',
    AUTHOR_EDIT: 'Edit',
    AUTHOR_ADD: 'Create',
    LOADING_DOCUMENT: 'Loading',
    SELECT: 'select',
    VISIBLE: 'Visible',
    SHOW: 'Shows',
    SHOW_LIST: 'Shows',
    SHOW_ADD: 'Create',
    SHOW_EDIT: 'Edit',
    MEANING: 'Blogs',
    MEANING_LIST: 'Blogs',
    MEANING_ADD: 'Create',
    MEANING_EDIT: 'Edit',
    SUBTITLE: 'Subtitle',
    NEWS_LIST: 'News',
    NEWS_ADD: 'Create',
    NEWS_EDIT: 'Edit',
    DRAFT: 'Draft',
    CHECK_CORRECTOR: 'Corrector check',
    CHECK_PLAGIARISM: 'Антиплагиат',
    CHECK_COORDS: 'Заполнены координаты',
    NEED_PHOTO: 'Установлено фото',
    NEED_VIDEO: 'Установлено видео',
    N_NEED_PHOTO: 'Фото',
    N_NEED_VIDEO: 'Видео',
    WORKFLOW: 'Workflow',
    NEED_RAYTER: 'Сдано журналистом',
    NEED_EDITOR: 'Проверено редактором',
    NEED_CORRECTOR: 'Проверено корректором',
    ERROR_SET_LOCK: 'Something went wrong',
    SUCCESS_SET_LOCK: 'Success',
    IN_MATERIAL: 'In document',
    DOCUMENT_LOCK: 'Document has been locked',
    REMOVE_LOCK: 'Remove',
    LOCK_REMOVE: 'Lock has been delete',
    LOCK_REMOVE_ERROR: 'Something went wrong',
    ERROR_CREATE_DOCUMENT: 'Something went wrong',
    DOCUMENT_CREATED: 'Document has been created',
    DOCUMENT_NOT_FOUND: 'Document not found',
    STASH_CHANGES: 'Stash not empty',
    DOCUMENT_LAST_EDIT_USER: 'Last update at',
    DOCUMENT_LAST_EDIT_STASH: 'Last stash at',
    DELETE_STASH: 'Remove stash',
    USER_BY: 'by',
    STATUS: 'Status',
    PUBLISH_CHECK: "Published, check",
    PUBLISH: "Published",
    ERROR_LOAD_WORKFLOW: 'Something went wrong',
    STATUS_NOT_SET: 'not set',
    IMAGE_FIELD: 'Image',
    VIDEO_FIELD: 'Vudei',
    RAITER_FIELD: 'Корреспондент',
    EDITOR_FIELD: 'Редактор',
    CHECK_FIELD: 'Корректор',
    VIEWS_AND_COMMENTS: 'Кол-во просмотров/кол-во комментов',
    LAST_UPDATED_WORKFLOW: 'Last updates at',
    DATE_PUBLISH: 'Published at',
    WORKFLOW_STATS: 'Status',
    COVER_WORKFLOW: 'Photo',
    VIEWS_COUNT: 'Views',
    COMMENTS_COUNT: 'Comments',
    LOCK_BY: 'Locked',
    READY: 'Ready',
    ARCHIVE: 'Archive',
    PUBLISH_AT_MAIN: 'Published at main',
    PUBLISH_AT_SMI2: 'Published at smi2',
    PUBLISH_AT_DZEN: 'Published at dzen',
    TYPE_ARTICLE: 'Article',
    TYPE_NEWS: 'News',
    TYPE_EPISODE: 'Show',
    TYPE_INFOGRAPHIC: 'Infographic',
    TYPE_TREND: 'Trend',
    TYPE_BANNER: 'Banner',
    TYPE_MEANING: "Opinions",
    ARCHIVE_LIST: 'Archive',
    TYPE: 'Type',
    CREATED_BY: 'Created by',
    ERROR_LOAD_ARCHIVE: 'Ошибка загрузки запаса',
    SEARCH_PLACEHOLDER: 'Title or copyright',
    EDITOR_INSERT_MEDIA: 'Media',
    INSERT_PLUGIN: 'Media elements',
    INSERT_IMAGE: 'Image',
    INSERT_VIDEO: 'Video',
    ERROR_LOAD_MEDIA: 'Failed to load media',
    INSERT: 'Insert',
    MEDIA_TITLE: 'Title',
    FILL_MEDIA_TITLE: 'ввидете описание',
    MEDIA_SOURCE: 'Source',
    MEDIA_COPYRIGHT: 'Copyright',
    MEDIA_COPYRIGHT_URL: 'Ссылка на источник',
    MEDIA_DELETE: 'Удалить',
    MEDIA_UPLOAD: 'Загрузить',
    EDIT_MEDIA: 'Редактирование медиа',
    SELECT_MEDIA: 'Archive',
    MEDIA_SOURCES: 'Источники',
    ERROR_UPDATE_MEDIA: 'Ошибка обновления',
    INSERT_EXTERNALVIDEO: 'External video',
    INCORRECT_SOURCE: 'Не известный источник',
    INSERT_CODE: 'Код',
    WIDTH: 'Ширина',
    HEIGHT: 'Высота',
    EMBEDDED: 'Code',
    EMBEDDED_EMPTY: 'Код не может быть пустым',
    ERROR_CREATE_CODE: 'Ошибка создания кода',
    VIDEOS: 'Видео',
    EVENTS: 'События',
    SERVICES: 'Услуги',
    PLACES: 'Места',
    FILMS: 'Фильмы',
    BOOKS: 'Книги',
    MUSIC: 'Музыка',
    ROUTES: 'Маршруты',
    PUBLISH_AT_RSS: 'Publish in rss',
    EVENTS_TEXT: 'Описание события',
    EVENTS_PROPS: 'Свойства события',
    SELECT_DATE: 'Выберите дату',
    DATE_FROM_EVENTS: 'Дата начала',
    DATE_TO_EVENTS: 'Дата окончания',
    BUTTON_NAME: 'Надпись на кнопке',
    BUTTON_LINK: 'Ссылка на кнопке',
    CONTACTS_TEL: 'Контактный телефон',
    CONTACTS_EMAIL: 'Контактный email',
    CONTACTS_WEBSITE: 'Вебсайт',
    SERVICES_PROPS: 'Свойства услуги',
    PLACES_PROPS: 'Свойства места',
    PROPS: 'Свойства',
    LINK: 'Ссылка',
    TYPE_VIDEOS: 'Видео',
    TYPE_EVENTS: 'События',
    TYPE_SERVICES: 'Услуги',
    TYPE_PLACES: 'Места',
    TYPE_FILMS: 'Фильмы',
    TYPE_BOOKS: 'Книги',
    TYPE_MUSIC: 'Музыка',
    TYPE_ROUTES: 'Маршруты',
    EDIT_SHORT: 'Редактировать',
    MEDIA_MAX_WIDTH: 'Максимальная ширина',
    SELECT_WORKFLOW: 'Select workflow',
    ARCHIVE_NEWSPAPER: 'Архив газеты',
    NEWSPAPER: 'Газета',
    NEWSPAPER_EDIT: 'Редактирование газеты',
    NEWSPAPER_NUMBER: 'Номер выпуска',
    NEWSPAPER_NUMBER_PLACEHOLDER: 'Заполните номер выпуска',
    NEWSPAPER_NUMBER_PLACEHOLDER_FILL: 'Ошибка заполнения номера',
    NEWSPAPER_PUBLISHED: 'Дата выпуска',
    NEWSPAPER_TYPE: 'Типы выпуска',
    SELECT_NEWSPAPER_TYPE: 'Выберите тип выпуска',
    PDF_LABEL: 'Выберите pdf',
    DELETE_PDF: "Удалить pdf",
    NEWSPAPER_LIST: 'Список газет',
    REGION_NEWSPAPER: 'Газеты района',
    NEWSPAPER_REGION: 'Район газеты',
    RNEWSPAPER_LIST: 'Газеты района',
    RNEWSPAPER_EDIT: 'Редактирование',
    VIDEO_LABEL: 'Видео',
    SELECT_SHOW: 'Выберите категорию',
    DIRECTORY: 'Справочники',
    MATERIAL_AUTHORS: 'Авторы',
    BOOKS_AUTHORS: 'Авторы книг',
    LIST_BOOK: 'Список',
    BOOKS_GENRE: 'Жанры',
    BOOK_AUTHORS_LIST: 'Список авторов книг',
    BOOK_AUTHORS_EDIT: 'Редактирование автора книг',
    AUTHOR_COVER: 'Cover',
    AUTHOR_NAME: 'Title',
    BOOK_GENRE_LIST: 'Жанры для книг',
    BOOK_GENRE_EDIT: 'Редактирование жанра',
    BOOK_TEXT: 'Описание книги',
    BOOK_AUTHOR: 'Авторы книги',
    GENRE: 'Жанры',
    SELECT_GENRE: 'Выберите жанры',
    TYPE_BOOK_GENRE: 'Жанры книг',
    TYPE_FILMS_GENRE: 'Жанры фильмов',
    TYPE_MUSIC_GENRE: 'Жанры музыки',
    AUTHOR_QUOTE: 'Цитата автора о книгие',
    AUTHOR_QUOTE_PLACEHOLDER: 'Введите циту автора о его книге',
    BOOK_AUTHOR_COMMENT: 'Автор комментария',
    BOOK_AUTHOR_COMMENT_SELECT: 'Выберите автора комментария',
    AUTHOR_COMMENT_SUBTITLE: 'Подпись автора',
    BOOK_OTHER_MATERIAL: 'Материалы по теме',
    BOOK_OTHER_MATERIAL_SELECT: 'Выберите метариалы по теме',
    BOOK_MORE_TITLE: 'Заголовок на блоке подробнее',
    READ_MORE_BOOKS: 'Readmore',
    SELECT_READ_MORE_BOOKS: 'Выберите книги',
    FILMS_LIST: 'Список',
    FILMS_AUTHORS: 'Режиссеры',
    FILMS_AUTHORS_LIST: 'Режиссеры',
    FILMS_GENRE: 'Жанры',
    FILMS_GENRE_LIST: 'Список жанров фильмов',
    FILMS_GENRE_EDIT: 'Редактирование жанра',
    FILMS_AUTHOR: 'Режиссеры',
    FILMS_AUTHOR_COMMENT: 'Автор комментария',
    FILMS_AUTHOR_COMMENT_SELECT: 'Выберите режиссера',
    FILM_TEXT: 'Описание фильма',
    VIEW_MORE: 'Смотрите также',

    MUSIC_LIST: 'Список',
    MUSIC_AUTHORS: 'Композиторы',
    MUSIC_AUTHORS_LIST: 'Композиторы',
    MUSIC_GENRE: 'Жанры',
    MUSIC_GENRE_LIST: 'Список жанров музыки',
    MUSIC_GENRE_EDIT: 'Редактирование жанра',
    MUSIC_AUTHOR: 'Композиторы',
    MUSIC_AUTHOR_COMMENT: 'Автор комментария',
    MUSIC_AUTHOR_COMMENT_SELECT: 'Выберите композитора',
    MUSIC_TEXT: 'Описание',
    MUSIC_EDIT: 'Редактирование',

    AUTHORIZATION_EXPIRED: 'Восстановление авторизации',
    YOUR_AUTHORIZATION_EXPIRED: 'Ваша авторизация истекла. Хотите продолжить?',
    RESUME: 'Продолжить',
    CURRENT_VERSION: 'Текущая версия',
    PREVIEW_VERSION: 'Предыдущая версия',
    YOU_ARE_USER_RECOVERY: 'Вы уверены, что хотите восстановить?',

    COMMENTS: 'Комментарии',
    PROFILES: 'Profiles',
    PROFILES_LIST: 'List',
    PROFILES_GROUP: 'Groups',
    PROFILES_PERMISSIONS: "Permissions",
    COMMENTS_LIST: 'Список комментариев',

    ERROR_LOAD_PROFILES: 'Ошибка загрузки профилей',
    PROFILE_LOGIN: 'Логин',
    PROFILE_CREATED: 'Дата регистрации',
    PROFILE_AVATAR: 'Аватар',
    PROFILE_EMAIL: 'ID',
    PROFILE_NAME: 'Имя',
    PROFILE_ACTIVE: 'Активен',
    PROFILE_APPROVED: 'Подтвержден',
    PROFILE_TYPE: 'Тип',
    EDIT_PROFILE: 'Редактировать профиль',
    PROFILE_SEARCH_PLACEHOLDER: 'Логин/email/имя/фамилию профиля',
    FILE_UPLOADED_SUCCESS: 'Media has been uploaded',

    ERROR_LOAD_GROUPS: 'Ошибка загрузки групп',
    GROUPS_SEARCH_PLACEHOLDER: 'Название/код группы',
    GROUPS_LIST: 'Список групп пользователей',
    CREATE_GROUP: 'Создать группу',

    PERMISSIONS_LIST: 'Список прав',
    CREATE_PERMISSION: 'Создать',
    PERMISSIONS_PLACEHOLDER: 'Код/название/описание права',
    ERROR_LOAD_PERMISSIONS: 'Ошибка загрузки прав',
    PERMISSION_CREATED: 'Право успешно создано',
    ERROR_CREATE_PERMISSION: 'Ошибка создания права. Право с этим кодом уже существует',
    PERMISSION_UPDATED: 'Право успешно обновлено',
    ERROR_UPDATE_PERMISSION: 'Ошибка обновления права',
    PERMISSION_CODE: 'Служебный идентификатор',
    PERMISSION_CODE_FILL: 'Заполните служебный идентификатор',
    PERMISSION_TITLE: 'Название',
    PERMISSION_TITLE_FILL: 'Заполните название права',
    PERMISSION_DESCRIPTION: 'Описание',
    DELETE_PERMISSION: 'Удалить право',
    EDIT_PERMISSION: 'Редактировать',
    DELETE_PERMISSION_REQUEST: 'Вы уверены, что хотите удалить право?',
    ERROR_DELETE_PERMISSION: 'Ошибка удаления права',
    SUCCESS_DELETE_PERMISSION: 'Право успешно удалено',
    UPDATE: 'Обновить',
    DESCRIPTION: 'Описание',
    GROUP_TITLE: 'Название группы',
    GROUP_PERMISSIONS: 'Права группы',
    GROUP_UPDATE_FINISH: 'Группа успешно обновлена',
    GROUP_UPDATE_ERROR: 'Ошибка обновления группы',
    GROUP_CREATE_FINISH: 'Группа успешно создана',
    GROUP_CREATE_ERROR: 'Ошибка создания группы',
    GROUP_DELETE_FINISH: 'Группа успешно удалена',
    GROUP_DELETE_ERROR: 'Ошибка удаления группы',
    DELETE_GROUP_REQUEST: 'Вы уверены, что хотите удалить группу?',
    DELETE_GROUP: 'удалить группу',
    EDIT_GROUP: 'редактировать группу',
    ACTIVE: 'Активна',
    PERMISSION: 'Права',
    GROUP_TITLE_FILL: 'Заполните название группы',
    UPDATE_GROUP: 'Обновление группы',
    PROFILE_EDIT: 'Редактирование профиля',
    ERROR_UPDATE_PROFILES: 'Ошибка обновления профиля, попробуйте пожалуйста позднее',
    PROFILES_UPDATED: `Профиль успешно обновлен`,
    ERROR_LOAD_PROFILE: 'Профиль не найден',
    PROFILE_BIRTHDAY: 'Дата рождения',
    PROFILE_ABOUT: 'Информация',
    PROFILE_ADDRESS: 'Район',
    PROFILE_LAST_NAME: 'Фамилия',
    PROFILE_PASSWORD: `Пароль`,
    PROFILE_GROUPS: 'Группы',
    PROFILE_INFO: 'Информация о профиле',
    PROFILE_SUBSCRIBES: 'Подписки профиля',
    THEATER: 'Театр',
    LIST_THEATER: 'Список',
    THEATER_TYPE: 'Тип',
    THEATER_TYPE_LIST: 'Типы выступлений',
    THEATER_LIST: 'Театр',
    SELECT_THEATER_TYPE: 'Выберите тип',
    THEATER_OTHER_MATERIAL: 'Материалы по теме',
    THEATER_MORE_TITLE: 'Заголовок на блоке cмотрите также',
    VIEW_OTHER_THEATER: 'Смотрите также',
    THEATER_OTHER_MATERIAL_SELECT: 'Выберите материалы',
    SELECT_VIEW_OTHER_THEATER: 'Выберите представления',
    TYPE_THEATER: 'Театр',
    TYPE_THEATER_TYPE: 'Типы театра',
    REPORTS: 'Отчеты',
    YANDEX: 'Яндекс',
    MATERIAL_STATS: 'Материалы',
    WIDGETS_STATS: 'Виджеты',
    BUDGET: 'Авторы',
    YANDEX_REPORT: 'Позиции в yandex',
    MATERIAL: 'Материалы',
    MATERIAL_REPORT: 'Отчет по материалам',
    OPINION_BATTLE: 'Битва мнений',
    BATTLE_LIST: 'Битва мнений',
    READ_TIME: "Время на чтение",
    OPINIONS: "Мнения",
    SELECT_OPINIONS: "Выберите мнения",
    WIDGETS_REPORT: "Отчет по виджетам",
    LOAD_REPORT: "Идет загрузка отчета",
    ERROR_LOAD_REPORT: "Ошибка загрузки отчета",
    VIEW_DOCS: 'Посмотреть документы',
    REPORT_TOTAL: 'Всего',
    REPORT_WEEK: '7 дней',
    REPORT_DAY: '24 часа',
    REPORT_HOUR: '1 час',
    PUBLISH_AT_GOOGLE: "Publish in google",
    PUBLISH_AT_PULSE: 'Опубликовать в mail pulse',
    PUBLISH_AT_MIRROR: 'Опубликовать в yandex зеркало',
    ONLINE: 'Прямая трансляция',
    SCHEDULE: 'Расписание',
    SCHEDULE_LIST: 'Расписание',
    SCHEDULE_EDIT: 'Редактирование',
    DATE_START: 'Дата начала',
    DATE_END: 'Дата окончания',
    DATE: 'Дата',
    SORT: 'Сортировка',
    DISPLAY_TITLE: 'Отображаемый заголовок',
    EDIT_WIDGET_ITEM: 'Редактирование заголовка',
    DISPLAY_NAME: 'Отображаемое имя',
    CHAT: 'Чат',
    DELETE_CHAT_MESSAGE: 'Удалить сообщение',
    RESTORE_CHAT_MESSAGE: 'Восстановить сообщение',
    USER_BLOCKS: 'Блокировки пользователя',
    USER_BANNED_TO: 'Пользователь заблокирован на',
    SECONDS: 'Секунд',
    PUBLISH_AT_NEWSLINE: 'Publish at newsline',
    LIST_EVENTS: 'Список',
    EVENTS_TYPES: 'Типы событий',
    ETYPE_LIST: 'Типы событий',
    EVENTS_CITY: 'Города',
    EVENT_TYPE: 'Тип события',
    SELECT_EVENT_TYPE: 'Выберите тип события',
    EVENT_CITY: 'Город события',
    SELECT_EVENT_CITY: 'Выберите город события',
    WIDGETS_MAINPAGE: 'Главная страница',
    DOWNLOAD_XLS: 'Скачать XLS',
    EXPORT_TO: 'Export',
    MOVE_TYPE: 'Material type',
    ERROR_MOVE_TYPE: 'Ошибка обновления типа документа',
    TYPE_UPDATED: 'Документ успешно перенесен',
    REDIRECT_MESSAGE: 'Вы будете переадресованы через несколько секунд',
    ERROR_LOAD_SEARCH: 'Ошибка загрузки, повторите пожалуйста позднее',
    YES: 'Да',
    NO: 'Нет',
    NOT_PUBLISHED: 'Не опубликован',
    TYPE_SHOW: 'Рубрики вечерки',


    SENDING: 'Идет отправка',
    CANCEL: 'Отменена',
    SEND: 'Отправлена',
    PREPARE: 'Ожидает отправки',
    CREATE_EMAIL: 'Создание новой рассылки',
    EMAIL_RECOMMEND: 'Рекомендовать для рассылки',

    AUTHORS_RATES: 'Коэффиценты',
    AUTHORS_RATE: 'Коэффицент',
    ARATES_LIST: 'Коэффиценты',
    AUTHORS_VIEWS: 'Гонорары',
    AVIEWS_LIST: 'Гонорары',
    RATE: 'Ставка',
    ARATES_EDIT: 'Коэффиценты',
    VIEWS_FROM: 'Просмотры от',
    VIEWS_TO: 'Просмотры до',
    FEE: 'Гонорар',
    VIDEO_REPORT: 'Отчет по видео',
    EMBARGO: 'Эмбарго',
    PUBLISH_AT_WIFI: 'Опубликовать в wifi',
    WIDGETS_EDITOR: 'Editors',
    WIDGETS_ADS: 'Ads',
    MAIN_MATERIAL: 'Main material',
    SELECT_MAIN_MATERIAL: 'Выберите главный материал',

    UNPUBLISH_ACTION: 'Unpublish',
    PUBLISH_ACTION: 'Publish',
    PUBLISH_POPCONFIRM: 'Publish material?',

    PUSH: 'Push notification',
    SEND_PUSH: 'Send push',
    PUSH_SEND: 'Push has been send',
    PUSH_SEND_ERROR: 'Failed to send push',
    POPULAR_MATERIAL: 'Знак качества',
    MAINPAGE_PUBLISH: 'Публикация на главной странице',
    MAINPAGE_FIELD: 'Главная',
    HIDE_GOOGLE_ADS: 'Hide ads',
    NO_INDEX: 'Noindex',
    FRENCH_QUOTES: "«ёлочки»",
    LONG_DASH: "тире —",
    MIDDLE_DASH: "тире –",
    INFOGRAPHIC_MATERIAL: 'Материал',
    SELECT_MATERIAL: 'Выберите материал',
    NOTIFICATIONS: 'Уведомления',
    NOTIFICATION_IN: 'Материал набрал просмотров',
    DOWNLOAD: 'Скачать',
    VIDEO_VIEWS: 'Видео полн.',
    CHAT_MESSAGES: 'Список сообщений в чате',
    COPYRIGHT: 'Копирайт',
    COPYRIGHT_PLACEHOLDER: 'автор/издание',
    COPYRIGHT_URL: 'Ссылка',
    COPYRIGHT_URL_PLACEHOLDER: 'Ссылка на источник',
    MEDIA_DESCRIPTION: 'Описание',
    MEDIA_KEYWORDS: 'Ключевые слова',

    POLLS: 'Опросы',
    POLLS_LIST: 'Список опросов',
    POLLS_EDIT: 'Редактирование опроса',
    TOTAL_VOTES: 'Количество голосов',
    TYPE_POLLS: 'Опросы',
    POLL_FINISH: 'Опрос окончен',

    SETTINGS: 'Настройки',
    HIDE_CHAT: 'Скрыть чат',

    SELECT_POLLS: 'Выберите опрос',
    STREAM_STATS: 'Ст. вещания',
    ERROR_LOAD_REPORT_STREAM: 'Ошибка загрузки отчета, попробуйте пожалуйста позднее',
    POLL_HIDE_RESULT: 'Скрыть всего проголосовавших',
    ADD_ADDRESS: 'Добавить адрес',

    ANTIPLAGIAT: 'Антиплагиат',
    AN_ORIGINAL: 'Уникальность',
    AN_PLAGIAT: 'Плагиат',
    AN_WORKFLOW_ORIGINAL: 'Антиплагиат уникальность',
    DOWNLOAD_DEVELOPER_LOG: 'Developer log',
    MAP_CATEGORY: 'Кат. карты',
    MCAT_LIST: 'Категории карты',
    COORDS_GROUP: 'Гео',
    MCAT_EDIT: 'Категории редактирование',
    MCAT_FIELD: 'Категория',
    SELECT_MCAT: 'Выберите категорию',
    ANONS_DATE: 'Отображать до',
    COLOR: 'Цвет',
    COLOR_PLACEHOLDER: 'Введите цвет',
    IS_ANONS: 'Анонс',
    SPECIAL_PROJECTS: 'Спецпроекты',
    SPECIAL_PROJECT: 'Спецпроект',
    COVID19: 'Коронавирус',
    MOSCOW_RECOVERED_TOTAL: 'Выздоровели',
    MOSCOW_RECOVERED_DAY: 'Выздоровели за сутки',
    MOSCOW_INFECTED_TOTAL: 'Заражено',
    MOSCOW_INFECTED_DAY: 'Заражено за сутки',
    MOSCOW_DEAD_TOTAL: 'Умерло',
    MOSCOW_DEAD_DAY: 'Умерло за сутки',
    COVID_PLACEHOLDER: 'Введите значение',
    COVID_HREF: 'Ссылка с виджета',
    COVID_TITLE: 'Заголовок',
    MEDIA_HAS_BEEN_UPDATED: 'Элемент успешно обновлен',
    MEDIA_HIDE_CONFIRM: 'Если скрыть элемент, он пропадет из поиска но будет доступен в документе. Вы уверены?',
    HIDE: 'Скрыть',
    ALL_NOTIFICATIONS: 'Все уведомления',
    DOCUMENT: 'Документ',
    NOTIFICATION_HIDE_ERROR: 'Ошибка удаления уведомления',
    DELETE_ALL: 'Удалить все',
    FILE_HAS_BEEN_UPLOADED: `Файл загружен`,

    /*media gallery*/
    MEDIA_GALLERY_LIST: `Media archive`,
    MEDIA_GALLERY_LIST_LOAD_ERROR: `Something went wrong`,
    MEDIAGALLERY_LOADING_ERROR_MESSAGE: `Something went wrong`,
    MEDIAGALLERY_LOADING_ERROR_DESCRIPTION: `Try again later`,
    MEDIAGALLERY_RELOAD: `Repeat`,
    MEDIAGALLERY_VIEW_CARD: 'Grid',
    MEDIAGALLERY_VIEW_LIST: 'List',
    MEDIAGALLERY_OBJECT: `Objects `,
    MEDIAGALLERY_TYPE_IMAGE: 'Images',
    MEDIAGALLERY_TYPE_COVER: 'Cover',
    MEDIAGALLERY_TYPE_VIDEO: 'Video',
    MEDIAGALLERY_EDIT_IMAGE: `Edit`,
    MEDIAGALLERY_DOWNLOAD_IMAGE: `Download original`,
    MEDIAGALLERY_DELETE_IMAGE: `Delete`,
    MEDIAGALLERY_DELETE_CONFIRM: `You are sure?`,
    MEDIAGALLERY_DELETE_OK: 'Yes, sure!',
    MEDIAGALLERY_DELETE_REJECT: `No`,
    MEDIAGALLERY_TYPE_PDF: 'PDF',
    MEDIAGALLERY_SELECT_TYPE: 'Select type',
    MEDIAGALLERY_FILL_SEARCH: 'Search query',
    MEDIGALLERY_UPLOAD_TITLE: `Select files`,
    MEDIAGALLERY_SINGLE_EDIT: 'Single',
    MEDIAGALLERY_GROUP_EDIT: 'Groups',
    MEDIGALLERY_CANNOT_GENERATE_PREVIEW: 'Error while render preview',
    MEDIAGALLERY_UPLOAD_TITLE: 'Upload files',

    ADMIN_WIDGET_CODE: 'Widget code',
    ADMIN_WIDGET_CODE_ERROR: 'Fill Widget code',
    ADMIN_WIDGET_CODE_PLACEHOLDER: 'Widget code',

    BOLD_TEXT: 'Select text',
    BOLD_TEXT_SELECTED: 'bold',

    /*спецпроект*/
    SPECIAL_PROJECT_TYPE: 'Special project type',
    SELECT_SPECIAL_PROJECT_TYPE: 'Special project type',
    SPECIAL_MEDIA_COUNT: 'Media count',
    POSITION: `Description`,
    AUTHOR_DESCRIPTION_REQUIRED: 'Fill description',
    AUTHOR_DESCRIPTION: "Description",
    AUTHOR_POSITION: 'Position',
    AUTHOR_POSITION_REQUIRED: 'Fill position',
    AUTHOR_PHOTO_REQUIRED: 'Upload author photo',
    AUTHOR_EMAIL: 'Author email',
    MEDIA_ADD_WATERMARK: 'Add watermark',

    /*profiles*/
    PROFILE_DEVICES: 'Profile devices',

    /*material gallery*/
    MATERIAL_GALLERY: 'Photos gallery',
    MGALLERY_LIST: 'Photos gallery',
    MGALLERY_EDIT: 'Edit',
    MGALLERY: 'Photos gallery',
    INSERT_MGALLERY: 'Photos gallery',
    MGALLERY_IMAGES:'Photos gallery',

    /*CROSS_LINKS*/
    'CROSS_LINKS': 'Crosslinks',

    /* STORIES */
    'STORIES': 'Stories',
    'STORIES_LIST': 'Stories list',
    'STORIES_EDIT': 'Edit',
    'STORIES_VIDEO': 'Video',
    'UPLOAD_NEW_VIDEO': 'New video',
    'UPLOAD_SEL_VIDEO': 'Archive',
    'STORIES_PREVIEW': 'Preview',
    'TYPE_STORIES': 'Stories',

    /* media */
    'MEDIA.DELETE': 'Delete',
    'MEDIA.EDIT': 'Edit',
    'DZEN_GROUP': 'Dzen',
    'DZEN_GREEN': 'Evergreen',

    'AUTHOR_TWITTER': 'Twitter',
    'AUTHOR_RATING': 'Authors rating',
    'TICKERS': 'Tickers',

    'TYPE_CATEGORY': 'Category',
    DOC_SEARCH_PLACEHOLDER: 'Search by title or url'
};

export default EN;
